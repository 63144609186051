import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import config from '../../utils/config'
import Box from '../../components/box'
import Popper from '../../components/hoverPopper'
import Typography from '../../components/typography'
import PricingCheck from '../../icons/pricingCheck'

const H2 = styled.h2`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-transform: uppercase;
  color: #000000;
`

const Bold = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;

  .subtext {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    color: ${props => props.theme.core.content.tertiary};
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
  }
`

const Level = styled.div`
  width: 20rem;
  align-self: center;
  justify-content: center;
  max-width: 100%;
  max-height: max-content;
  background: #f6f6f6;
  border-top: 0.4375rem solid ${props => props.color};
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;

  .price {
    padding-top: 2.5rem;
    padding-left: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .label {
    padding: 1.5rem;
    box-sizing: border-box;
    background: #eeeeee;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${props => props.theme.core.content.secondary};

    b {
      color: #000;
    }
  }

  .lower {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1.5rem;
      display: flex;
      font-size: 0.875rem;
      line-height: 1.25rem;

      svg {
        margin-right: 0.625rem;
      }
    }
  }
`

const CTAButton = styled(Link)`
  padding: 1rem 4.6875rem;
  background: #000000;
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1rem;
  text-decoration: none;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  .subtext {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    color: ${props => props.theme.core.content.tertiary};
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .dotted-underline {
    border-bottom: 0.0938rem dashed #000000;
  }
`

export const Base = ({ callToActionText }) => (
  <Level color="#62CCF6">
    <Box className="price">
      <H2>Base</H2>
      <Bold>
        $0<span className="subtext">/mo</span>
      </Bold>
    </Box>
    <Box className="label">
      For small teams trying out Pulse for an <b>unlimited period</b> of time.
    </Box>
    <Box className="lower">
      <ul className="list">
        <li>
          <div>
            <PricingCheck stroke="#93D3F4" />
          </div>
          Up to 50 users regardless of their roles
        </li>
        <li>
          <div>
            <PricingCheck stroke="#93D3F4" />
          </div>
          Share unlimited updates with your team
        </li>
        <li>
          <div>
            <PricingCheck stroke="#93D3F4" />
          </div>
          Up to 8 streams to broadcast updates, gather feedback, share
          decisions, and more
        </li>
      </ul>
      <CTAButton to="/pricing">{callToActionText || 'Get Started'}</CTAButton>
    </Box>
  </Level>
)

export const Advanced = ({ callToActionText }) => (
  <Level color="#FB7036">
    <Box className="price">
      <H2>Advanced</H2>
      <Price>
        <Bold>$5</Bold>
        <Popper
          anchor={
            <span className="subtext ">
              /<span className="dotted-underline">mo</span>*
            </span>
          }
          children={
            <>
              <Typography textType="paragraph-small">
                Billed annually or $10 month-to-month
              </Typography>
            </>
          }
        />
      </Price>
    </Box>
    <Box className="label">
      For small and medium-sized businesses looking to{' '}
      <b>support internal growth.</b>
    </Box>
    <Box className="lower">
      <ul className="list">
        <li>
          <div>
            <PricingCheck stroke="#FB7036" />
          </div>
          Unlimited users regardless of their roles
        </li>
        <li>
          <div>
            <PricingCheck stroke="#FB7036" />
          </div>
          Unlock unlimited streams and give extra flexibility to your people
        </li>
        <li>
          <div>
            <PricingCheck stroke="#FB7036" />
          </div>
          Create Teams to further personalize your Start Page, send groups
          requests and accomplish more
        </li>
      </ul>
      <CTAButton to="/pricing">{callToActionText || 'Get Started'}</CTAButton>
    </Box>
  </Level>
)

export const Enterprise = () => (
  <Level color="#BE6EF5">
    <Box className="price">
      <H2>ENTERPRISE</H2>
      <Bold>Let's talk</Bold>
    </Box>
    <Box className="label">
      For larger businesses or those with additional{' '}
      <b>administration needs.</b>
    </Box>
    <Box className="lower">
      <ul className="list">
        <li>
          <div>
            <PricingCheck stroke="#BE6EF5" />
          </div>
          Unlimited users regardless of their roles
        </li>
        <li>
          <div>
            <PricingCheck stroke="#BE6EF5" />
          </div>
          Advanced identity management through SAML-based SSO and real-time
          Active Directory sync with OneLogin, Okta, and more
        </li>
        <li>
          <div>
            <PricingCheck stroke="#BE6EF5" />
          </div>
          99.99% guaranteed uptime and 24/7 support
        </li>
      </ul>
      <CTAButton onClick={() => window.open(config.scheduleEnterpriseDemoUrl)}>
        Contact Sales
      </CTAButton>
    </Box>
  </Level>
)
