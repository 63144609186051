import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Nav from '../components/nav'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Box from '../components/box'
import Image from '../components/image'
import RowLayout from '../components/rowLayout'
import TabPickerInverted from '../components/tabPickerInverted'
import Hidden from '../components/hidden'
import Accordion from '../components/accordion'
import AccordionSingleSelect from '../containers/accordionSingleSelect'
import checkUtmParams from '../utils/checkUtmParams'
import {
  H1,
  H3,
  CopyBody,
  CopyBodyH2,
  CopySubText,
  Ul,
  Li,
  Purple,
  Green,
  Yellow,
  Uppercase,
  TryPulse,
} from './index'
import { Base, Advanced } from '../containers/pricing'
import Typography from '../components/typography'

import HomePatternSvg from '../assets/svg/home/home-pattern-one.svg'
import DarkModeSvg from '../assets/svg/ext-dark-mode.svg'
import OptimizeLoadSvg from '../assets/svg/ext-opt-load.svg'
import TempHideSvg from '../assets/svg/ext-temp-hide.svg'
import DeployableSvg from '../assets/svg/ext-comp-wide.svg'
import BrowserSupportSvg from '../assets/svg/ext-browser-support.svg'
import BlackCheckSvg from '../assets/svg/home/black-check.svg'
import QuotesSvg from '../assets/svg/home/quotes.svg'
import StarsSvg from '../assets/svg/five-stars.svg'
import useHotjarScript from '../utils/hooks/useHotjarScript'
import Button from '../components/button'

const BrowserExtensionPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        browserExtMenu: file(relativePath: { eq: "browser-ext-menu.png" }) {
          childImageSharp {
            fixed(width: 560, height: 674, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        browserExtMenuMobile: file(
          relativePath: { eq: "browser-ext-menu-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 560, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extKeyUpdates: file(relativePath: { eq: "ext-key-updates.png" }) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extTimezones: file(relativePath: { eq: "ext-timezones.png" }) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extBookmarks: file(relativePath: { eq: "ext-bookmarks.png" }) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extWorkEvents: file(relativePath: { eq: "ext-work-events.png" }) {
          childImageSharp {
            fluid(maxWidth: 742, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        startPageFull: file(relativePath: { eq: "start-page-full.png" }) {
          childImageSharp {
            fixed(width: 883, height: 541, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        startPageMobile: file(relativePath: { eq: "start-page-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 602, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <Container>
      <SEO
        title="Browser Extension - Show key updates on the browser new tab"
        description="Share important company or team-wide updates across everyone’s browser new tab. Where no one can miss them."
        ogImage="/assets/ogimages/pulse-browser-extension.png"
        twImage="/assets/ogimages/twitter/pulse-browser-extension.png"
      />
      <Nav />
      <HomePatternSvg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      />
      <RowLayout id="hero-layout">
        <HeroHeader>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '4.375rem',
              zIndex: 2,
              position: 'relative',
            }}
          >
            <div style={{ width: '100%' }}>
              <H1 style={{ maxWidth: '100%' }}>
                Show updates on everyone’s new tab
              </H1>
              <Button onClick={() => navigate('/signup')}>
                {' '}
                Get Pulse For Free{' '}
              </Button>
              <Box id="rating-container">
                <StarsSvg />
                <span id="stars-rating">5 star rating</span>
              </Box>
            </div>
          </Box>
          <CopyBodyH2
            style={{
              marginTop: '3rem',
              marginBottom: '2rem',
              maxWidth: '100%',
            }}
          >
            A breakthrough browser extension to display key{' '}
            <Green>company updates</Green>, <Purple>important events</Purple>,{' '}
            and <Yellow>resources</Yellow> across everyone's new browser tab.
          </CopyBodyH2>
        </HeroHeader>
        <Box
          style={{
            paddingTop: '2.5rem',
            zIndex: 3,
          }}
        >
          <Hidden widthDown={600}>
            <Img
              fixed={data.startPageFull.childImageSharp.fixed}
              fadeIn={false}
              style={{
                marginRight: '-400px',
              }}
            />
          </Hidden>

          <Hidden widthUp={599}>
            <Img
              fluid={data.startPageMobile.childImageSharp.fluid}
              fadeIn={false}
            />
          </Hidden>
        </Box>
      </RowLayout>
      <RowLayout id="tab-picker-inverted">
        <Box>
          <Hidden widthDown={1024}>
            <TabPickerInverted
              options={[
                {
                  icon: <Uppercase>Updates</Uppercase>,
                  title: 'Where no one can miss them',
                  description:
                    'Get important company or team-wide updates right in front of your people.',
                  component: (
                    <Box width="46.375rem" height="auto">
                      <Img
                        fluid={data.extKeyUpdates.childImageSharp.fluid}
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
                {
                  icon: <Uppercase>People Spotlight</Uppercase>,
                  title:
                    'Welcome new people, celebrate b-days, and work anniversaries',
                  description:
                    'Share decisions in streams and give everyone context and extra visibility.',
                  component: (
                    <Box width="46.475rem" height="auto">
                      <Img
                        fluid={data.extWorkEvents.childImageSharp.fluid}
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
                {
                  icon: <Uppercase>Bookmarks</Uppercase>,
                  title: 'Quicklinks to internal resources',
                  description:
                    'Share, review and quickly iterate with your team without never loosing track of things.',
                  component: (
                    <Box width="46.375rem" height="auto">
                      <Img
                        fluid={data.extBookmarks.childImageSharp.fluid}
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
                {
                  icon: <Uppercase>Time Zones</Uppercase>,
                  title: 'Know when and where your team is',
                  description:
                    'Request key people to review and acknowledge your key updates.',
                  component: (
                    <Box width="46.375rem" height="auto">
                      <Img
                        fluid={data.extTimezones.childImageSharp.fluid}
                        fadeIn={false}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </Hidden>
          <Hidden widthUp={1023}>
            <Box style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
              <AccordionSingleSelect>
                {({ handleAccordionChange, expandedId }) => (
                  <>
                    <Accordion
                      title="Updates"
                      header="Where no one can miss them"
                      handleChange={() => handleAccordionChange('1')}
                      open={expandedId === '1'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Get important company or team-wide updates right in
                            front of your people.
                          </Typography>
                          <Img
                            fluid={data.extKeyUpdates.childImageSharp.fluid}
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                    <Accordion
                      title="People Spotlight"
                      header="Welcome new people, celebrate b-days, and work anniversaries"
                      handleChange={() => handleAccordionChange('2')}
                      open={expandedId === '2'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Share decisions in streams and give everyone context
                            and extra visibility.
                          </Typography>
                          <Img
                            fluid={data.extWorkEvents.childImageSharp.fluid}
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                    <Accordion
                      title="Bookmarks"
                      header={'Quicklinks to internal resources'}
                      handleChange={() => handleAccordionChange('3')}
                      open={expandedId === '3'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Share, review and quickly iterate with your team
                            without never loosing track of things.
                          </Typography>
                          <Img
                            fluid={data.extBookmarks.childImageSharp.fluid}
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                    <Accordion
                      title="Time Zones"
                      handleChange={() => handleAccordionChange('4')}
                      open={expandedId === '4'}
                      header={'Know when and where your team is'}
                      content={
                        <>
                          <Typography
                            colorType="colors.gray.300"
                            style={{ marginBottom: '1rem' }}
                          >
                            Request key people to review and acknowledge your
                            key updates.
                          </Typography>
                          <Img
                            fluid={data.extTimezones.childImageSharp.fluid}
                            fadeIn={false}
                          />
                        </>
                      }
                    />
                  </>
                )}
              </AccordionSingleSelect>
            </Box>
          </Hidden>
        </Box>
      </RowLayout>
      <RowLayout id="subtle-and-lightweight">
        <Box>
          <Box style={{ paddingTop: '5rem', flex: 1 }}>
            <H3>
              {' '}
              Subtle and lightweight. <br />
              But above all fast.{' '}
            </H3>
            <CopySubText>
              Our browser is where we get work done, and on average we open a
              new tab 400+ times every day. We know that if we have to build
              something that you use that much, it should be subtle and
              attractive, but above all, it should be fast.
            </CopySubText>
            <Li>
              <Ul>
                <DarkModeSvg />
                Dark mode available
              </Ul>
              <Ul>
                <OptimizeLoadSvg />
                Optimized to load instantly
              </Ul>
              <Ul>
                <TempHideSvg />
                Temporarily hide the start page with one click
              </Ul>
              <Ul>
                <DeployableSvg />
                Deployable company-wide with MDM systems
              </Ul>
              <Ul>
                <BrowserSupportSvg />
                Supports Chrome, Firefox, Edge, and Brave
              </Ul>
            </Li>
          </Box>
        </Box>
        <Box style={{ flex: 1, width: '100%' }}>
          <Hidden widthDown={600}>
            <Img
              fixed={data.browserExtMenu.childImageSharp.fixed}
              fadeIn={false}
            />
          </Hidden>
          <Hidden widthUp={599}>
            <Img
              fluid={data.browserExtMenuMobile.childImageSharp.fluid}
              fadeIn={false}
            />
          </Hidden>
        </Box>
      </RowLayout>
      <Hidden widthDown={600}>
        <RowLayout
          style={{
            background: 'none',
            flexDirection: 'column',
            paddingTop: '4rem',
          }}
        >
          <QuotesSvg />
          <Quote>
            With the Pulse Start Page we were able bootstrap internal adoption
            and get into people’s workflow right from the get-go. Rolled out to
            everyone’s laptop in a few seconds and worked great ever since.
          </Quote>
        </RowLayout>
        <CloudAcademyFounder
          style={{ backgroundImage: `url(/_home/cloud-academy-bg-quote.svg)` }}
        >
          <RowLayout
            style={{
              background: 'none',
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Image
                src="giacomo.png"
                alt="Cloud Academy"
                style={{
                  height: '5.375rem',
                  width: '5.375rem',
                  borderRadius: '0.625rem',
                }}
                nativeImage
              />
              <Box style={{ marginLeft: '1.5rem' }}>
                <h3 id="header">Giacomo Marinangeli</h3>
                <p id="subtext">CTO/Founder at Cloud Academy</p>
              </Box>
            </Box>
          </RowLayout>
        </CloudAcademyFounder>
        <Box style={{ paddingTop: '1.75rem', backgroundColor: '#FFF' }} />
        <RowLayout>
          <TryPulse>
            <Box style={{ width: '22.625rem' }}>
              <H3> Try Pulse in a snap of fingers</H3>
              <CopySubText style={{ color: '#FFFFFF' }}>
                The best way to learn what Pulse can do for you is to start with
                our Free plan and see it yourself.
              </CopySubText>
              <Li>
                <Ul>
                  <BlackCheckSvg />
                  Free up to 50 users
                </Ul>
                <Ul>
                  <BlackCheckSvg />
                  Unlimited updates (up to 5GB)
                </Ul>
                <Ul>
                  <BlackCheckSvg />
                  Unlimited history review
                </Ul>
              </Li>
              <Button
                style={{
                  marginTop: '2rem',
                  marginBottom: '1rem',
                }}
                onClick={() => navigate('/signup')}
              >
                Get Pulse For Free
              </Button>
              <Link to={'/pricing'} id="see-our-plans-link">
                Want more? See our plans
              </Link>
            </Box>
            <Box id="pricing-levels">
              <Base callToActionText="Learn more" />
              <Advanced callToActionText="Learn more" />
            </Box>
          </TryPulse>
        </RowLayout>
      </Hidden>
      <Footer />
    </Container>
  )
}

const Container = styled.div`
  #hero-layout {
    padding-top: 1rem;
    padding-bottom: 5rem;
    display: flex;

    @media (min-width: 600px) {
      padding-right: 0;
    }
  }

  #see-our-plans-link {
    display: block;
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
  }

  #tab-picker-inverted {
    background: none;
    flex-direction: column;
    padding-top: 0rem;
    padding-bottom: 0rem;
    @media (min-width: 600px) {
      padding-bottom: 6rem;
      padding-top: 7.5rem;
    }
  }

  #subtle-and-lightweight {
    padding-top: 0rem;
    padding-bottom: 0rem;
    flex-direction: column;
    @media (min-width: 600px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
      flex-direction: row;
    }
  }
`

const HeroHeader = styled.div`
  max-width: 100%;
  width: 41.375rem;

  #hero-subtext {
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};

    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  #rating-container {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
  }

  #stars-rating {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
    margin-left: 0.75rem;
  }
`

const Quote = styled.p`
  font-weight: 900;
  font-size: 3.25rem;
  line-height: 4.375rem;
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-style: normal;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`

const CloudAcademyFounder = styled.div`
  width: 100%;
  height: 8.375rem;
  background-size: cover;
  overflow-x: hidden;

  #header {
    font-style: normal;
    font-family: ${props => props.theme.typography.fontFamilyGT};
    font-weight: 900;
    font-size: 1.75rem;
    line-height: 2.125rem;
    color: #ffffff;
    margin: 0;
    margin-bottom: 0.25rem;
  }

  #subtext {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
  }
`

export default BrowserExtensionPage
